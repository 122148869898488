import * as React from "react";

import type { SVGProps } from "react";

export function SvgChartGood(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 78 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m1 27.137 1.568 1.447a.909.909 0 0 0 1.353-.136L5.473 26.3a.909.909 0 0 1 1.435-.05l2.323 2.788a.909.909 0 0 0 1.464-.093l2.396-3.76a.909.909 0 0 1 1.554.034l2.084 3.606a.909.909 0 0 0 .787.454h1.515a.909.909 0 0 0 .901-.79l.344-2.623a.91.91 0 0 1 .545-.718l2.251-.959a.909.909 0 0 1 1.057.258l.883 1.069a.909.909 0 0 0 1.437-.047l3.347-4.633a.909.909 0 0 0 .135-.276L33.05 9.937a.909.909 0 0 1 .872-.653h.434a.909.909 0 0 0 .876-.666l1.597-5.75c.24-.865 1.455-.896 1.738-.045l1.395 4.182c.264.795 1.371.839 1.698.068l1-2.35a.718.718 0 0 1 1.315-.016v0a.718.718 0 0 0 1.294.031l1.599-3.135a.909.909 0 0 1 1.622.007l1.197 2.392a.909.909 0 0 0 1.017.479l1.959-.452a.909.909 0 0 1 .785.186l2.004 1.665a.909.909 0 0 1 .301.48l1.824 7.322c.214.857 1.4.937 1.727.116l1.233-3.103a.909.909 0 0 1 1.54-.25l.554.658a.909.909 0 0 0 .695.323h3.19a.909.909 0 0 0 .779-.44l.849-1.411a.91.91 0 0 1 .499-.396l8.704-2.817"
        stroke="#00BF89"
        strokeLinecap="round"
      />
    </svg>
  );
}
