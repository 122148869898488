import React, { useEffect, useRef } from "react";

import clsx from "clsx";
import { animate, scroll } from "motion";

import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

import { commonStackedGridCell } from "~styles/common/common.stacked-grid.css";
import { global_backgroundImage } from "~styles/common/global_backgroundImage.css";
import { clampBuilder } from "~styles/css_preprocessing_utils/clampBuilder";
import { gridFullWidthRem } from "~styles/vars/varsWidth.css";

import { Box } from "~components/Box";
import { Video } from "~components/Video";

import { GridStats } from "~sections/GridStats";

import type { VideoProps } from "~components/Video";
import type { GridStatsProps } from "~sections/GridStats";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

type AnimatedVideoMaskWithStatsProps = {
  video?: Array<StoryblokBlok & VideoProps>;
  stats?: GridStatsProps["stats"];
};

const { spacing20 } = vars.spacing || {};
const distanceFromLeft = calc.subtract("50%", calc.divide(gridFullWidthRem, 2));
const negativeSpacing20 = calc.multiply(spacing20, -1);

// Inset = top, right, bottom, left, border-radius
const CLIP_PATH_INITIAL = `inset(0 ${negativeSpacing20} 0 50vw round ${vars.borderRadius.xxl})`;
const CLIP_PATH_END = `inset(0 ${negativeSpacing20} 0 ${distanceFromLeft} round ${vars.borderRadius.xxl})`;

const STATS_GRID_TEMPLATE_COLUMNS: GetSprinklesArgs["gridTemplateColumns"] = {
  mobile: 1,
  tablet: 3,
};
const STATS_DISPLAY: GetSprinklesArgs["display"] = {
  mobile: "none",
  tablet: "grid",
};
export function AnimatedVideoMaskWithStats({
  video: videoBlokArray,
  stats,
}: AnimatedVideoMaskWithStatsProps) {
  const refContainer = useRef<HTMLDivElement>(null);

  const [video] = videoBlokArray || [];

  useEffect(() => {
    if (typeof window === "undefined") return;

    if (refContainer.current) {
      const maskedContent = refContainer.current?.querySelector(
        "[data-motion='masked-content']"
      );

      if (!maskedContent) return;

      scroll(
        animate(maskedContent, {
          clipPath: [CLIP_PATH_INITIAL, CLIP_PATH_END],
        }),
        {
          target: refContainer.current,
          offset: ["start end", "start start"],
        }
      );
    }
  }, []);

  return (
    <Box
      ref={refContainer}
      display={STATS_DISPLAY}
      alignItems="center"
      marginBottom="spacing10"
    >
      <Box
        display="flex"
        position="relative"
        aria-hidden="true"
        __minHeight={clampBuilder(12, 40)}
        alignItems="center"
        color="text_highContrast"
        data-motion="masked-content"
        __clipPath={CLIP_PATH_INITIAL}
        className={commonStackedGridCell}
      >
        <Video
          isHiddenOnMobile
          position="absolute"
          inset="0"
          videoClassName={global_backgroundImage}
          {...video}
        />
      </Box>
      {stats && (
        <GridStats
          zIndex="1"
          stats={stats}
          width="100%"
          maxWidth="gridSpan10"
          marginX="auto"
          gridTemplateColumns={STATS_GRID_TEMPLATE_COLUMNS}
          className={clsx(commonStackedGridCell)}
          isAnimated
        />
      )}
    </Box>
  );
}
