import * as React from "react";

import type { SVGProps } from "react";

export function SvgIllustrationPokerChip(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 102 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M47.222 20.486V4.86c10.278-.556 20.486 3.935 24.306 6.25l-8.334 13.541c-2.5-2.5-11.69-3.82-15.972-4.166ZM75.83 37.003l13.532-7.813c5.62 8.623 6.835 19.71 6.74 24.174l-15.894-.446c.915-3.415-2.537-12.033-4.377-15.915Z"
        fill="#fff"
      />
      <path
        d="m63.915 24.625 7.912-13.473C80.97 15.876 87.5 24.918 89.622 28.848l-14.043 7.458c-.89-3.422-8.147-9.213-11.664-11.68ZM31 25.963l-8.06-13.386C31.46 6.8 42.523 5.382 46.989 5.395l-.155 15.9c-3.431-.853-11.985 2.757-15.833 4.668Z"
        fill="#ECECEF"
      />
      <path
        d="m76.736 36.806 12.5-8.334M80.903 53.125h14.583M30.903 25.347l-8.68-13.194"
        stroke="#B4B4C0"
        strokeWidth={2}
      />
      <circle
        cx={47.917}
        cy={52.083}
        r={46.917}
        stroke="#B4B4C0"
        strokeWidth={2}
      />
      <circle
        cx={47.917}
        cy={53.472}
        r={32.333}
        fill="#F09000"
        fillOpacity={0.2}
        stroke="#B4B4C0"
        strokeWidth={2}
      />
      <path
        d="M47.222 20.486V4.514M63.542 24.305l8.333-13.541"
        stroke="#B4B4C0"
        strokeWidth={2}
      />
      <path
        d="M46.635 32.08 32.806 50.909a2 2 0 0 0-.009 2.355l13.828 19.123a2 2 0 0 0 3.253-.016l13.533-19.123a2 2 0 0 0-.008-2.323L49.87 32.096a2 2 0 0 0-3.235-.016Z"
        fill="#fff"
        stroke="#B4B4C0"
        strokeWidth={2}
      />
    </svg>
  );
}
