import * as React from "react";

import { animate, inView } from "motion";

import { vars } from "~styles/themes/theme.css";

import type { SVGProps } from "react";

export type SvgIconTickProps = SVGProps<SVGSVGElement>;

export function SvgIconTick(props: SvgIconTickProps) {
  const refContainer = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    if (!refContainer.current || typeof window === "undefined") return;

    const circle = refContainer.current?.querySelector<SVGCircleElement>(
      '[data-motion="circle"]'
    );
    const path = refContainer.current?.querySelector<SVGPathElement>(
      '[data-motion="path"]'
    );

    if (!circle || !path) return;

    inView(refContainer.current, () => {
      animate(
        circle,
        {
          strokeDashoffset: [circle.getTotalLength(), 0],
          strokeDasharray: [circle.getTotalLength(), circle.getTotalLength()],
        },
        {
          duration: 1.5,
          delay: 0.5,
        }
      );
      animate(
        path,
        {
          strokeDashoffset: [path.getTotalLength(), 0],
          strokeDasharray: [path.getTotalLength(), path.getTotalLength()],
        },
        {
          duration: 1,
          delay: 0.5,
        }
      );
    });
  });

  return (
    <svg
      ref={refContainer}
      viewBox="0 0 114 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={57}
        cy={57}
        r={54}
        data-motion="circle"
        stroke={vars.color.neutral.white}
        strokeWidth={6}
      />
      <path
        d="m29 55 20 20 36-36"
        data-motion="path"
        stroke={vars.color.neutral.white}
        strokeWidth={6}
      />
    </svg>
  );
}
