import * as React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

interface SvgIllustrationDepositProps extends Omit<GetSprinklesArgs, "color"> {
  className?: string;
}

export function SvgIllustrationDeposit({
  className: userClassName,
  ...rest
}: SvgIllustrationDepositProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <svg
      viewBox="0 0 275 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(userClassName, getSprinkles(atomProps))}
      {...otherProps}
    >
      <rect x={0.6} y={0.6} width={62.8} height={62.8} rx={15.4} fill="#fff" />
      <rect
        x={0.6}
        y={0.6}
        width={62.8}
        height={62.8}
        rx={15.4}
        stroke="#ECECEF"
        strokeWidth={1.2}
      />
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#1F1F25"
      >
        <path d="M27.429 29.714 20.57 36.57l2.286 2.285L29.715 32l-2.286-2.286Zm2.286 16L32 48l16-16-2.285-2.286-16 16ZM38.856 32l-2.286-2.286-11.428 11.428 2.286 2.286L38.857 32Z" />
        <path d="m16 32 2.286 2.286L32 20.572l9.143 9.142 2.285-2.286L32 16 16 32Z" />
      </g>
      <rect
        x={211.6}
        y={0.6}
        width={62.8}
        height={62.8}
        rx={15.4}
        fill="#fff"
      />
      <path
        d="M227 16v32h32V16h-32Zm16 29.012c-7.562 0-13.689-3.313-13.689-7.417 0-2.95 3.153-5.487 7.745-6.687a5.84 5.84 0 0 0 .786 3.224l4.283 7.492c.329.564.546 1.167.673 1.788l.183-.31a5.957 5.957 0 0 0 0-5.944l-3.445-5.958a5.968 5.968 0 0 1 .019-5.925l.184-.31c.127.62.343 1.218.673 1.788l2.004 3.459 3.135 5.435c.329.565.545 1.167.677 1.784l.179-.311a5.948 5.948 0 0 0 0-5.939l-3.426-5.943a5.957 5.957 0 0 1 0-5.944l.184-.306c.127.617.343 1.22.673 1.784l4.282 7.416c.475.843.748 1.789.786 2.72 4.593 1.2 7.765 3.737 7.783 6.687 0 4.104-6.127 7.417-13.689 7.417Z"
        fill="#EC0000"
      />
      <path
        d="M256.689 37.595c0 4.104-6.127 7.417-13.689 7.417s-13.689-3.313-13.689-7.417c0-2.95 3.152-5.487 7.745-6.687a5.84 5.84 0 0 0 .786 3.224l4.283 7.492c.329.564.546 1.167.673 1.788l.183-.31a5.957 5.957 0 0 0 0-5.944l-3.445-5.958a5.968 5.968 0 0 1 .019-5.925l.184-.31c.127.62.343 1.219.673 1.788l2.004 3.459 3.135 5.435c.329.565.545 1.167.677 1.784l.179-.311a5.948 5.948 0 0 0 0-5.939l-3.426-5.943a5.957 5.957 0 0 1 0-5.944l.184-.306c.127.617.343 1.22.673 1.784l4.282 7.416c.475.843.748 1.789.786 2.72 4.593 1.2 7.765 3.737 7.783 6.687Z"
        fill="#fff"
      />
      <rect
        x={211.6}
        y={0.6}
        width={62.8}
        height={62.8}
        rx={15.4}
        stroke="#ECECEF"
        strokeWidth={1.2}
      />
      <circle
        cx={112}
        cy={33}
        r={4}
        transform="rotate(180 112 33)"
        fill="#ECECEF"
      />
      <circle
        cx={100}
        cy={33}
        r={4}
        transform="rotate(180 100 33)"
        fill="#D0D0D8"
      />
      <circle
        cx={88}
        cy={33}
        r={4}
        transform="rotate(180 88 33)"
        fill="#B4B4C0"
      />
      <circle
        cx={76}
        cy={33}
        r={4}
        transform="rotate(180 76 33)"
        fill="#646477"
      />
      <circle cx={163} cy={33} r={4} fill="#ECECEF" />
      <circle cx={175} cy={33} r={4} fill="#D0D0D8" />
      <circle cx={187} cy={33} r={4} fill="#B4B4C0" />
      <circle cx={199} cy={33} r={4} fill="#646477" />
      <path
        d="m123.252 41.23 13.31 7.58c1.128.674 1.641.201 2.105-.006l14.118-8.1c.502-.32.848-.595.848-1.159 0-.564-.428-.848-.684-1.007L149.147 36l3.614-2.097c.586-.293 1.209-.953 1.209-1.78v-5.584c0-1.451-.831-1.905-1.229-2.124l-13.852-7.995c-1.199-.792-2.179-.27-2.584 0l-13.895 8.078c-.624.424-.822.88-.818 1.34.006.695.723 1.22.821 1.285l3.609 2.145-3.835 2.337c-.601.366-.643 1.048-.643 1.048-.044.305-.044 5.442-.044 5.442 0 1.348.123 2.227 1.752 3.134Zm27.874-1.574-13.528 7.574-13.077-7.37 4.102-2.354 8.488 4.854c.257.184.721.17.963.039l9.156-5.299 3.896 2.556Zm1.15-8.084-3.654-2.247 3.643-2.168.011 4.415Zm-13.674-13.06 12.459 7.265-4.088 2.5-8.38-5.08.009-4.686Zm-1.007 6.28 7.559 4.583-7.559 4.363H137.586l-7.728-4.463 7.728-4.484h.009Zm-13.692 1.041 12.707-7.338v4.703l-8.819 4.982-3.888-2.347Zm4.017 4.567 9.178 5.297a1.005 1.005 0 0 0 .964.006l8.817-5.284 3.985 2.418-13.279 7.585-13.517-7.787 3.852-2.235Zm-4.628 4 3.555 2.03-3.555 2.065V34.4Z"
        fill="#1F1F25"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(16 16)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
