import * as React from "react";

import type { SVGProps } from "react";

export function SvgIllustrationPokerCard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 167 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.8}>
        <rect
          opacity={0.5}
          x={69}
          y={4.917}
          width={92.154}
          height={139.627}
          rx={6.638}
          fill="#D0D0D8"
        />
        <rect
          x={73.917}
          width={92.154}
          height={139.627}
          rx={6.638}
          fill="#fff"
        />
        <path
          d="M136.052 65.476c3.894 3.628 4.047 9.75.306 13.68-3.665 3.856-9.849 4.007-13.819.379l-.535-.605v5.518h2.443c1.298 0 2.444 1.134 2.444 2.419 0 1.36-1.146 2.418-2.444 2.418h-9.772c-1.374 0-2.443-1.058-2.443-2.418 0-1.285 1.069-2.42 2.443-2.42h2.443V78.93l-.611.605c-3.97 3.628-10.154 3.477-13.819-.378-3.741-3.93-3.588-10.053.305-13.681l14.812-13.908a2.51 2.51 0 0 1 3.436 0l14.811 13.908ZM154.808 126.775l-5.816-14.362a1.143 1.143 0 0 0-1.054-.71c-.473 0-.909.261-1.091.71l-5.816 14.362a1.209 1.209 0 0 0 .618 1.571c.618.262 1.272-.038 1.526-.636l1.164-2.842h7.161l1.163 2.842a1.19 1.19 0 0 0 1.091.748.943.943 0 0 0 .436-.112c.582-.262.873-.973.618-1.571Zm-9.524-4.339 2.654-6.432 2.617 6.432h-5.271ZM98.957 26.242 93.141 11.88a1.143 1.143 0 0 0-1.054-.71c-.473 0-.91.261-1.09.71L85.18 26.242a1.21 1.21 0 0 0 .618 1.57c.618.262 1.272-.037 1.526-.635l1.164-2.843h7.161l1.163 2.843a1.19 1.19 0 0 0 1.091.748c.145 0 .29-.038.436-.113.582-.261.873-.972.618-1.57Zm-9.524-4.339 2.654-6.432 2.617 6.432h-5.271Z"
          fill="#B4B4C0"
        />
      </g>
      <g opacity={0.8}>
        <circle cx={74.728} cy={167.887} r={73.752} fill="#ECECEF" />
        <circle cx={74.728} cy={167.887} r={43.022} fill="#D0D0D8" />
        <path
          d="M81.522 241.756c14.995-1.363 28.699-7.191 39.773-16.145l-22.05-22.049a42.999 42.999 0 0 1-17.723 7.088v31.106ZM32.126 175.357a42.99 42.99 0 0 0 6.986 17.106l-22.05 22.05C8.233 203.591 2.443 190.11.977 175.357h31.15ZM66.699 125.454a43.01 43.01 0 0 0-17.213 7.385l-22.004-22.004C38.371 101.817 51.88 95.854 66.7 94.257v31.197ZM117.331 160.534a43.02 43.02 0 0 0-7.496-17.83l22.004-22.004c9.143 11.039 15.146 24.773 16.641 39.834h-31.149Z"
          fill="#B4B4C0"
        />
        <path
          d="M49.487 202.917a43.008 43.008 0 0 0 17.212 7.385v31.197c-14.818-1.597-28.328-7.56-39.216-16.578l22.004-22.004ZM39.111 143.293a43.005 43.005 0 0 0-6.985 17.106H.976c1.465-14.753 7.256-28.234 16.086-39.155l22.05 22.049ZM99.246 132.194a43 43 0 0 0-17.724-7.088V94c14.995 1.363 28.7 7.191 39.774 16.145l-22.05 22.049ZM109.834 193.052a42.998 42.998 0 0 0 7.497-17.83h31.149c-1.495 15.061-7.499 28.795-16.642 39.835l-22.004-22.005Z"
          fill="#646477"
        />
      </g>
    </svg>
  );
}
