import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerBalanceCardItem } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import type { AnimLayerBalanceCardItemProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationAllAccountsProps extends StoryblokBlok {
  className?: string;
  linked?: string;
  accounts?: Array<StoryblokBlok & AnimLayerBalanceCardItemProps>;
}

export function AnimationIllustrationAllAccounts({
  className: userClassName,
  linked,
  accounts,
  ...rest
}: AnimationIllustrationAllAccountsProps) {
  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        className={clsx(userClassName)}
        width="gridSpan3"
        {...rest}
      >
        {accounts && accounts[0] && (
          <AnimLayerBalanceCardItem {...accounts[0]} />
        )}
        <Box opacity="0.5">
          <Box
            data-motion="secondary"
            textAppearance="body_sm"
            paddingY="spacing1"
            marginLeft="spacing3"
            fontWeight="semibold"
          >
            {linked}
          </Box>
          <Box marginX="spacing2">
            {accounts && accounts[1] && (
              <AnimLayerBalanceCardItem {...accounts[1]} />
            )}
            <Box marginX="spacing2" marginTop="spacing1">
              {accounts && accounts[2] && (
                <AnimLayerBalanceCardItem {...accounts[2]} />
              )}
            </Box>
          </Box>
        </Box>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
