import * as React from "react";

import { animate, scroll, spring } from "motion";

import { useMatchMedia } from "~hooks/use_match_media/use_match_media";

import { getIsInEditor } from "~utils/storyblok/getIsInEditor";

import { Box } from "../../components/Box";
import * as styles from "./styles.css";

import type { BoxProps } from "../../components/Box";
import type { ScrollOptions } from "motion";

export type AnimatedMaskTitleProps = BoxProps & {
  text: string;
  offset?: ScrollOptions["offset"];
};

export function AnimatedMaskTitle({
  text,
  as = "div",
  textAppearance = "h3",
  fontFamily = "formula",
  display = "block",
  marginBottom = "spacing10",
  offset = ["start 75%", "start start"],

  ...rest
}: AnimatedMaskTitleProps) {
  const refElement = React.useRef<HTMLSpanElement>(null);
  const isViewportMobile = useMatchMedia({ max: "MOBILE" });

  React.useEffect(() => {
    if (typeof window === "undefined" || getIsInEditor()) return;

    if (refElement.current && !isViewportMobile) {
      scroll(
        animate(
          refElement.current,
          {
            backgroundSize: ["0% 100%", "100% 100%"],
          },
          {
            easing: spring({
              stiffness: 5,
              damping: 10,
              mass: 20,
            }),
          }
        ),
        {
          target: refElement.current,
          offset,
        }
      );
    }
  }, [isViewportMobile, offset]);

  return (
    <Box
      {...rest}
      as={as}
      display={display}
      ref={refElement}
      fontFamily={fontFamily}
      className={styles.mask}
      textAppearance={textAppearance}
      marginBottom={marginBottom}
    >
      {text}
    </Box>
  );
}
