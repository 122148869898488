import * as React from "react";

import { vars } from "~styles/themes/theme.css";

import type { SVGProps } from "react";

export function SvgIconPokerChip(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.415 18.794c.082.2.082.424 0 .624l-.433 1.034a2.231 2.231 0 0 0 .18 2.056 2.136 2.136 0 0 0 1.774.992c.288 0 .572-.06.838-.173l5.14-2.187c.076 2.746.511 6.11 2.473 7.871a.68.68 0 0 0 .45.178h2.26a.687.687 0 0 0 .637-.455.71.71 0 0 0-.187-.77c-1.576-1.415-2.94-4.295-3.281-6.743l4.953 2.106c.528.223 1.12.223 1.648 0a2.17 2.17 0 0 0 1.162-1.193 2.234 2.234 0 0 0-.008-1.682l-.44-1.035a.814.814 0 0 1-.007-.623l.433-1.035c.223-.535.229-1.14.014-1.68a2.164 2.164 0 0 0-1.16-1.194 2.099 2.099 0 0 0-1.646 0l-.03.014-3.719 1.8 1.662-3.944c.22-.538.22-1.143 0-1.68a2.163 2.163 0 0 0-1.17-1.184 2.1 2.1 0 0 0-1.645.014l-1.014.442a.767.767 0 0 1-.61 0l-1.014-.442a2.1 2.1 0 0 0-1.648-.013c-.529.221-.95.649-1.168 1.188a2.227 2.227 0 0 0 .005 1.682l1.659 3.937-3.72-1.8a2.1 2.1 0 0 0-1.676-.013 2.163 2.163 0 0 0-1.159 1.193c-.214.54-.21 1.145.015 1.681l.432 1.034ZM22.1 27.8c-.437-.435-1.185-1.405-1.579-3.902a14.884 14.884 0 0 0 2.025 3.902H22.1Zm-9.433-9.55-.432-1.034a.79.79 0 0 1 .163-.87.74.74 0 0 1 .838-.18l5.333 2.58a.67.67 0 0 0 .764-.128c.204-.2.265-.51.153-.775L17.12 12.22a.805.805 0 0 1 .17-.87.766.766 0 0 1 .852-.166l1.014.442a2.111 2.111 0 0 0 1.68 0l1.013-.442a.766.766 0 0 1 .85.164.805.805 0 0 1 .176.864l-2.371 5.629a.706.706 0 0 0 .152.775.67.67 0 0 0 .765.128l5.333-2.58a.766.766 0 0 1 .843.184c.219.23.281.57.158.865l-.432 1.035a2.238 2.238 0 0 0 0 1.715L27.754 21a.806.806 0 0 1-.163.87.766.766 0 0 1-.85.174l-5.684-2.418a2.708 2.708 0 0 0-2.123 0l-5.684 2.418a.765.765 0 0 1-.85-.174.806.806 0 0 1-.164-.87l.432-1.035a2.239 2.239 0 0 0 0-1.715Z"
        fill={vars.color.text.text_lowContrast}
      />
      <path
        d="M20.007 40a19.99 19.99 0 0 0 18.471-12.342 19.993 19.993 0 0 0-4.334-21.789A19.993 19.993 0 1 0 5.87 34.143a19.867 19.867 0 0 0 14.137 5.855V40ZM6.204 20.712a13.768 13.768 0 0 0 3.549 8.552l-3.37 3.37a18.512 18.512 0 0 1-4.945-11.922h4.766Zm4.546 9.549a13.768 13.768 0 0 0 8.552 3.549v4.765A18.515 18.515 0 0 1 7.381 33.63l3.369-3.368Zm9.961 3.549a13.766 13.766 0 0 0 8.596-3.59l3.368 3.369a18.515 18.515 0 0 1-11.964 4.986V33.81Zm9.588-4.59a13.765 13.765 0 0 0 3.51-8.508h4.766a18.51 18.51 0 0 1-4.907 11.878L30.3 29.22Zm3.51-9.917a13.766 13.766 0 0 0-3.549-8.553l3.37-3.368a18.513 18.513 0 0 1 4.946 11.92h-4.767Zm-4.545-9.549a13.767 13.767 0 0 0-8.553-3.55V1.44a18.515 18.515 0 0 1 11.922 4.946l-3.37 3.37Zm-9.962-3.55a13.766 13.766 0 0 0-8.509 3.511l-3.37-3.37A18.513 18.513 0 0 1 19.303 1.44v4.766Zm.704 1.393c6.844-.001 12.413 5.567 12.413 12.41 0 6.844-5.569 12.411-12.413 12.411-6.843 0-12.412-5.567-12.412-12.412 0-6.843 5.569-12.411 12.412-12.411v.002ZM6.424 7.339l3.368 3.368a13.767 13.767 0 0 0-3.589 8.595H1.438A18.513 18.513 0 0 1 6.424 7.34Z"
        fill={vars.color.text.text_lowContrast}
      />
    </svg>
  );
}
