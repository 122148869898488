import * as React from "react";

import { animate, inView } from "motion";

import { vars } from "~styles/themes/theme.css";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import type { SVGProps } from "react";

export type SvgAnimProgressSemicircleProps = SVGProps<SVGSVGElement>;

export function SvgAnimProgressSemicircle(
  props: SvgAnimProgressSemicircleProps
) {
  const refContainer = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    if (!refContainer.current || typeof window === "undefined") return;

    const path = refContainer.current?.querySelector<SVGPathElement>(
      '[data-motion="path"]'
    );

    if (!path) return;

    const pathLength = path.getTotalLength();

    inView(refContainer.current, () => {
      animate(
        path,
        {
          strokeDashoffset: [pathLength, pathLength * 0.4],
          strokeDasharray: [pathLength, pathLength],
        },
        {
          duration: 1.5,
          delay: 0.25,
        }
      );
    });
  });

  return (
    <svg
      ref={refContainer}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 680 404.793"
      style={{
        position: "absolute",
        inset: 0,
      }}
      {...props}
    >
      <path
        d="M41.335 379.547c-2.909-16.667-4.363-33.818-4.363-51.272C36.972 160.943 172.668 25.246 340 25.246s303.028 135.697 303.028 303.029c0 17.454-1.454 34.605-4.363 51.272"
        fill="none"
        stroke={vars.color.border.default}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={16}
      />
      <path
        d="M41.335 379.547c-2.909-16.667-4.363-33.818-4.363-51.272C36.972 160.943 172.668 25.246 340 25.246s303.028 135.697 303.028 303.029c0 17.454-1.454 34.605-4.363 51.272"
        data-motion="path"
        fill="none"
        stroke={colorTokens_latest.Secondary.Water_azure[130].value}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={16}
      />
    </svg>
  );
}
