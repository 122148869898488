import * as React from "react";

import type { SVGProps } from "react";

export function SvgIllustrationCards(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 78 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={30.383}
        y={1.257}
        width={47.823}
        height={73.489}
        rx={5}
        transform="rotate(17.689 30.383 1.257)"
        fill="#fff"
        stroke="#B4B4C0"
        strokeWidth={2}
      />
      <path
        d="m38.32 17.63-.636-8.353a.618.618 0 0 0-.426-.54.625.625 0 0 0-.679.188l-5.355 6.442a.654.654 0 0 0 .06.91c.276.237.662.19.891-.076l1.066-1.274 3.69 1.177.131 1.655c.02.261.196.487.44.565a.512.512 0 0 0 .242.013.661.661 0 0 0 .577-.707Zm-4.192-3.8 2.423-2.877.292 3.744-2.715-.866Z"
        fill="#B4B4C0"
      />
      <rect
        x={1}
        y={25.511}
        width={47.823}
        height={73.489}
        rx={5}
        fill="#fff"
        stroke="#B4B4C0"
        strokeWidth={2}
      />
      <path
        d="M33.593 59.91c2.105 1.961 2.188 5.272.165 7.397-1.98 2.084-5.324 2.165-7.47.204l-.29-.327v2.983h1.321c.702 0 1.321.613 1.321 1.308 0 .735-.62 1.307-1.32 1.307h-5.284c-.743 0-1.321-.572-1.321-1.307 0-.695.578-1.308 1.32-1.308h1.322v-2.983l-.33.327c-2.147 1.961-5.49 1.88-7.472-.204-2.023-2.126-1.94-5.436.165-7.397l8.008-7.52c.537-.49 1.32-.49 1.857 0l8.008 7.52Z"
        fill="#F09000"
        fillOpacity={0.2}
      />
      <path
        d="m43.734 93.051-3.145-7.764a.618.618 0 0 0-.57-.384.625.625 0 0 0-.59.384l-3.144 7.764a.654.654 0 0 0 .334.85.623.623 0 0 0 .825-.344l.63-1.537h3.871l.629 1.537a.644.644 0 0 0 .59.404c.078 0 .157-.02.235-.06a.661.661 0 0 0 .335-.85Zm-5.15-2.345 1.435-3.478 1.415 3.478h-2.85ZM13.538 38.699l-3.145-7.764a.618.618 0 0 0-.57-.385.625.625 0 0 0-.59.384L6.09 38.7a.654.654 0 0 0 .334.85.623.623 0 0 0 .826-.344l.629-1.537h3.871l.63 1.537a.644.644 0 0 0 .589.404c.079 0 .157-.02.236-.06a.661.661 0 0 0 .334-.85Zm-5.15-2.346 1.435-3.477 1.415 3.477h-2.85Z"
        fill="#B4B4C0"
      />
    </svg>
  );
}
