import * as React from "react";

import type { SVGProps } from "react";

export function SvgChartBad(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 78 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 28.35h2.147c.29 0 .565-.125.756-.342l.914-1.043c.37-.42 1.01-.46 1.427-.086l2.726 2.443c.185.165.423.257.671.257h3.086c.195 0 .386-.057.549-.163l.946-.617c.4-.261.93-.204 1.265.136l.935.948c.35.355.906.4 1.309.106l4.49-3.277a1.005 1.005 0 0 1 1.308.106l.738.748a1.005 1.005 0 0 0 1.484-.058l1.67-1.978c.137-.163.322-.278.529-.328l2.94-.719c.32-.078.581-.309.7-.616l1.288-3.36c.109-.284.34-.503.627-.597l2.005-.653c.236-.077.436-.239.56-.454l.383-.668a1.005 1.005 0 0 1 1.725-.03l1.745 2.799c.25.402.749.575 1.195.413l3.015-1.091c.29-.106.517-.338.613-.632l2.391-7.273c.161-.489.666-.777 1.169-.668l1.258.273c.41.09.834-.087 1.06-.44l1.433-2.242c.3-.47.928-.606 1.396-.3l.52.339a.58.58 0 0 0 .82-.197v0a.58.58 0 0 1 .937-.098l1.616 1.806a1.005 1.005 0 0 0 1.701-.346l1.928-5.656a1.005 1.005 0 0 1 1.331-.607l2.198.896c.358.145.767.073 1.053-.185l2.463-2.223c.087-.078.187-.14.295-.185L72.667.721a1.005 1.005 0 0 1 1.003.143l3.68 2.92"
        stroke="#FF3855"
        strokeWidth={1.005}
        strokeLinecap="round"
      />
    </svg>
  );
}
