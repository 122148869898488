import * as React from "react";

import { animate, inView } from "motion";

import { vars } from "~styles/themes/theme.css";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import type { SVGProps } from "react";

export function SvgIconTickShield(props: SVGProps<SVGSVGElement>) {
  const refContainer = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    if (!refContainer.current || typeof window === "undefined") return;

    const path = refContainer.current?.querySelector<SVGPathElement>(
      '[data-motion="path"]'
    );

    if (!path) return;

    inView(refContainer.current, () => {
      animate(
        path,
        {
          strokeDashoffset: [path.getTotalLength(), 0],
          strokeDasharray: [path.getTotalLength(), path.getTotalLength()],
        },
        {
          duration: 1,
          delay: 0.5,
        }
      );
    });
  });

  return (
    <svg
      ref={refContainer}
      viewBox="0 0 136 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.004 23.372 67.432.352a3 3 0 0 1 2.003.005l64.552 23.01a3 3 0 0 1 1.992 2.826v79.926c.297 8.025-2.488 26.217-15.997 34.778-12.943 8.202-37.798 23.608-50.02 31.159a2.965 2.965 0 0 1-3.077.033c-12.8-7.539-38.608-22.978-50.887-31.192C3.2 132.336 0 114.144 0 106.119V26.202a3 3 0 0 1 2.004-2.83z"
        fill={colorTokens_latest.Secondary.Water_azure[130].value}
      />
      <path
        data-motion="path"
        d="m40 78 20 20 36-36"
        stroke={vars.color.neutral.white}
        strokeWidth={6}
      />
    </svg>
  );
}
