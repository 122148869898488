import * as React from "react";

import { vars } from "~styles/themes/theme.css";

import type { SVGProps } from "react";

export function SvgIconSlotMachine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 10.684a3.468 3.468 0 0 0-3.453-3.452 3.468 3.468 0 0 0-3.453 3.452c0 1.642 1.18 3.032 2.737 3.369v6.105a1.33 1.33 0 0 1-1.347 1.348h-1.431V17.42a2.444 2.444 0 0 0-2.442-2.442h-.422v-1.263a4.088 4.088 0 0 0-4.084-4.084h-1.81C22.947 6.895 20 5 16.547 5 13.095 5 10.105 6.895 8.8 9.547H6.947a4.088 4.088 0 0 0-4.084 4.085v1.263h-.42A2.444 2.444 0 0 0 0 17.337v9.81a2.444 2.444 0 0 0 2.442 2.443h.421v1.263a4.088 4.088 0 0 0 4.084 4.084h19.158a4.088 4.088 0 0 0 4.084-4.084v-1.264h.422a2.444 2.444 0 0 0 2.442-2.442v-4.084h1.39a2.956 2.956 0 0 0 2.947-2.947V14.01A3.427 3.427 0 0 0 40 10.684ZM16.547 6.642c2.485 0 4.674 1.18 5.895 2.948h-11.79c1.222-1.769 3.411-2.948 5.895-2.948ZM4.505 13.674A2.488 2.488 0 0 1 6.99 11.19h19.158a2.489 2.489 0 0 1 2.485 2.484v1.263H4.505v-1.263Zm16.463 2.905v11.453h-8.842V16.579h8.842ZM1.642 27.23v-9.81c0-.463.38-.842.842-.842h8.042v11.453H2.442a.774.774 0 0 1-.8-.8Zm26.948 3.706a2.489 2.489 0 0 1-2.485 2.484H6.948a2.489 2.489 0 0 1-2.485-2.484v-1.264h24.084v1.264h.043Zm2.863-3.706a.844.844 0 0 1-.842.843h-8.042V16.579h8.042c.463 0 .842.379.842.842v9.81Zm5.094-14.694a1.869 1.869 0 0 1-1.852-1.853c0-1.01.842-1.853 1.852-1.853 1.011 0 1.853.842 1.853 1.853a1.87 1.87 0 0 1-1.853 1.853Z"
        fill={vars.color.text.text_lowContrast}
      />
      <path
        d="M27.326 21.758h-.589a.798.798 0 0 1-.8-.884c.042-.421.421-.716.842-.716h.716c.252 0 .463.168.547.421 0 .084.084.127.168.127h.8a.181.181 0 0 0 .169-.169c-.084-.8-.716-1.431-1.516-1.474v-.8a.181.181 0 0 0-.168-.168h-.8a.181.181 0 0 0-.169.168v.8a1.917 1.917 0 0 0-1.642 2.064c.085.968.969 1.684 1.98 1.684h.505c.463 0 .841.378.8.884-.043.421-.422.716-.843.716h-.715c-.253 0-.463-.169-.548-.421 0-.085-.084-.127-.168-.127h-.8a.181.181 0 0 0-.168.169c.084.8.715 1.431 1.515 1.473v.8c0 .085.085.169.169.169h.8a.181.181 0 0 0 .168-.169v-.8c.842-.126 1.558-.8 1.642-1.684.084-1.095-.8-2.063-1.895-2.063ZM16.337 22.853h.505c.463 0 .842.38.8.885-.042.42-.421.715-.842.715h-.716c-.252 0-.463-.168-.547-.42 0-.085-.084-.127-.168-.127h-.8a.18.18 0 0 0-.169.168c.085.8.716 1.432 1.516 1.474v.8c0 .084.084.168.168.168h.8a.18.18 0 0 0 .169-.168v-.842c.842-.126 1.557-.8 1.642-1.684.126-1.137-.758-2.063-1.895-2.063h-.59a.798.798 0 0 1-.8-.884c.043-.422.422-.716.843-.716h.715c.253 0 .464.168.548.42 0 .085.084.127.168.127h.8a.181.181 0 0 0 .168-.168c-.084-.8-.715-1.432-1.515-1.474v-.8a.181.181 0 0 0-.169-.168h-.8a.181.181 0 0 0-.168.168v.8a1.917 1.917 0 0 0-1.642 2.063c.084 1.01.968 1.726 1.979 1.726ZM6.358 21.758h-.59a.798.798 0 0 1-.8-.884c.042-.421.422-.716.842-.716h.716c.253 0 .463.168.548.421 0 .084.084.127.168.127h.8a.181.181 0 0 0 .168-.169c-.084-.8-.716-1.431-1.516-1.474v-.8a.181.181 0 0 0-.168-.168h-.8a.181.181 0 0 0-.168.168v.8a1.917 1.917 0 0 0-1.642 2.064c.084.968.968 1.684 1.979 1.684H6.4c.463 0 .842.378.8.884-.042.421-.421.716-.842.716h-.716c-.252 0-.463-.169-.547-.421 0-.085-.085-.127-.169-.127h-.8a.181.181 0 0 0-.168.169c.084.8.716 1.431 1.516 1.473v.8c0 .085.084.169.168.169h.8a.181.181 0 0 0 .168-.169v-.8c.843-.126 1.559-.8 1.643-1.684.084-1.095-.8-2.063-1.895-2.063Z"
        fill={vars.color.text.text_lowContrast}
      />
    </svg>
  );
}
