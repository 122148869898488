import * as React from "react";

import type { SVGProps } from "react";

export function SvgTruelayerConnection(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={131}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={40}
        cy={18}
        r={4}
        transform="rotate(180 40 18)"
        fill="#ECECEF"
      />
      <circle
        cx={28}
        cy={18}
        r={4}
        transform="rotate(180 28 18)"
        fill="#D0D0D8"
      />
      <circle
        cx={16}
        cy={18}
        r={4}
        transform="rotate(180 16 18)"
        fill="#B4B4C0"
      />
      <circle
        cx={4}
        cy={18}
        r={4}
        transform="rotate(180 4 18)"
        fill="#646477"
      />
      <circle cx={91} cy={18} r={4} fill="#ECECEF" />
      <circle cx={103} cy={18} r={4} fill="#D0D0D8" />
      <circle cx={115} cy={18} r={4} fill="#B4B4C0" />
      <circle cx={127} cy={18} r={4} fill="#646477" />
      <path
        d="m51.2 26.469 13.31 7.58c1.128.675 1.641.201 2.105-.006l14.118-8.1c.502-.32.848-.595.848-1.159 0-.564-.428-.848-.684-1.007l-3.802-2.538 3.614-2.097c.586-.293 1.21-.953 1.21-1.78v-5.583c0-1.452-.831-1.905-1.23-2.125L66.837 1.659c-1.2-.792-2.18-.27-2.584 0L50.358 9.737c-.623.425-.822.88-.818 1.34.006.695.723 1.22.822 1.285l3.608 2.146-3.835 2.336c-.6.366-.643 1.048-.643 1.048-.044.306-.044 5.443-.044 5.443 0 1.348.123 2.227 1.752 3.134Zm27.875-1.574-13.529 7.574L52.47 25.1l4.102-2.354 8.488 4.854c.257.184.72.17.963.039l9.156-5.298 3.897 2.555Zm1.15-8.084-3.655-2.246 3.643-2.169.012 4.415ZM66.55 3.751l12.459 7.265-4.088 2.5-8.38-5.079.009-4.686Zm-1.007 6.28 7.559 4.583-7.559 4.363-.005.001-.004-.001-7.728-4.463 7.728-4.483h.01Zm-13.692 1.041 12.707-7.338v4.703l-8.82 4.982-3.887-2.347Zm4.017 4.567 9.178 5.298a1.006 1.006 0 0 0 .965.005l8.816-5.284 3.985 2.418-13.279 7.585-13.517-7.787 3.852-2.235ZM51.24 19.64l3.555 2.03-3.555 2.064V19.64Z"
        fill="#1F1F25"
      />
    </svg>
  );
}
