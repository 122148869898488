import * as React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

interface SvgIllustrationWithdrawFsProps
  extends Omit<GetSprinklesArgs, "color"> {
  className?: string;
}

export function SvgIllustrationWithdrawFs({
  className: userClassName,
  ...rest
}: SvgIllustrationWithdrawFsProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <svg
      viewBox="0 0 358 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(userClassName, getSprinkles(atomProps))}
      {...otherProps}
    >
      <path
        d="M1 1h352M1 25h352M1 49h352M1 73h352"
        stroke="#ECECEF"
        strokeDasharray="6 6"
      />
      <path d="M1 97h352" stroke="#ECECEF" />
      <path
        d="M13.366 16.995 1 58.807V97h351.003V18.55l-11.487 1.25-12.162 13.385h-12.5l-12.513-15.83-23.333 2.084-13.838-.889-11.103-2.881-12.551 16.522-11.264-2.79L219.622 8l-12.507 8.589-23.976-1.931-12.069 3.892-12.229 23.789-12.23-2.3-12.229-20.24-12.068-3.21-12.873 1.961-23.655-2.544-3.719 13.395-20.096 1.748-12-11.35L38.81 35.682 13.366 16.996Z"
        fill="url(#a)"
        style={{
          mixBlendMode: "multiply",
        }}
        opacity={0.3}
      />
      <g filter="url(#b)">
        <circle cx={345} cy={16} r={8} fill="#1A73E8" />
        <circle cx={345} cy={16} r={7} stroke="#fff" strokeWidth={2} />
      </g>
      <path
        d="m1 59.157 12.366-42.161L38.809 35.33 49.972 19.8l12 11.349L82.066 29.4l3.72-13.395 23.654 2.544 12.873-1.961 12.068 3.21 12.229 20.24 12.23 2.3L171.07 18.55l12.069-3.893 23.976 1.932L219.622 8l11.63 21.401 11.264 2.79 12.551-16.522 11.103 2.881 13.838.89 23.333-2.085 12.513 15.83h12.5L340.516 19.8l4.151-1.25"
        stroke="#1A73E8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="a"
          x1={176.501}
          y1={18.862}
          x2={176.501}
          y2={97}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A73E8" />
          <stop offset={0.276} stopColor="#9ACAFF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <filter
          id="b"
          x={332.629}
          y={8}
          width={24.741}
          height={24.741}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4.371} />
          <feGaussianBlur stdDeviation={2.185} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.0509804 0 0 0 0 0.0392157 0 0 0 0 0.172549 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3098_56756"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3098_56756"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
