import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";
import { AnimationPaymentsListItemStatus } from "~animations/in_body_illustrations/AnimationPaymentsList/AnimationPaymentsListItemStatus";

import * as styles from "./styles.css";

import type { StatusOption } from "~animations/in_body_illustrations/AnimationPaymentsList/statuses";
import type { StoryblokBlok } from "~types/storyblok.types";

interface AnimationIdentityListItem {
  label: string;
  value: string;
  icon: StatusOption;
}

export interface AnimIdentityDetailsProps extends StoryblokBlok {
  title: string;
  listItems: Array<StoryblokBlok & AnimationIdentityListItem>;
  className?: string;
}

export function AnimIdentityDetails({
  title,
  listItems,
  className: userClassName,
  ...rest
}: AnimIdentityDetailsProps) {
  return (
    <AnimatedElement
      animationLevel="primary"
      display="flex"
      flexDirection="column"
      gap="spacing1"
      padding="spacing3"
      minWidth="gridSpan3"
      borderRadius="md"
      className={clsx(userClassName, variantBackgroundColor.background_sand)}
      {...rest}
    >
      <Box
        textAppearance="body_lg"
        fontWeight="bold"
        textAlign="center"
        marginBottom="spacing2"
        data-motion={ANIMATED.secondary}
      >
        {title}
      </Box>
      {listItems &&
        listItems.map((item) => {
          return (
            <Box
              display="flex"
              gap="spacing1"
              marginBottom="spacing0"
              flexDirection="row"
              key={item._uid}
              alignItems="center"
              height="spacing3"
              textAppearance="body_sm"
              data-motion={ANIMATED.secondary}
            >
              <Box width="33%" whiteSpace="nowrap">
                {item.label}
              </Box>
              <Box as="span" className={styles.pill} paddingX="spacing1">
                {item.value}
              </Box>
              <Box marginLeft="auto" width="spacing3">
                <AnimationPaymentsListItemStatus status={item.icon} />
              </Box>
            </Box>
          );
        })}
    </AnimatedElement>
  );
}
