import * as React from "react";

import { animate, inView } from "motion";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import type { SVGProps } from "react";

export type SvgIconLoaderProps = SVGProps<SVGSVGElement>;

export function SvgIconLoader(props: SvgIconLoaderProps) {
  const refContainer = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    if (!refContainer.current || typeof window === "undefined") return;

    const circle = refContainer.current?.querySelector<SVGCircleElement>(
      '[data-motion="circle"]'
    );

    if (!circle) return;

    inView(refContainer.current, () => {
      animate(
        circle,
        {
          strokeDashoffset: [
            circle.getTotalLength(),
            circle.getTotalLength() * -1,
          ],
          strokeDasharray: [
            circle.getTotalLength() * 0.5,
            circle.getTotalLength() * 0.5,
          ],
        },
        {
          repeat: Infinity,
          duration: 1.5,
          delay: 0.5,
        }
      );
    });
  });

  return (
    <svg
      ref={refContainer}
      viewBox="0 0 114 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={57}
        cy={57}
        r={54}
        data-motion="circle"
        stroke={colorTokens_latest.Secondary.Water_azure[130].value}
        strokeWidth={6}
      />
    </svg>
  );
}
